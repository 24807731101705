import React, { useEffect, useMemo } from "react";
import { useStore } from "react-redux";
import { closeDialog, closeLoadingDialog, openDialog, openLoadingDialog } from "../Utils/Data/actions/gui";
import { LinearProgress, Typography } from "@material-ui/core";
import { useDeviceDataActiveViewLoadingState } from "../Utils/Data/hooks/deviceDataView";
import { useHistoryState } from "../Utils/Data/hooks/server";
import { useIntl } from "react-intl";
import { ShowError } from "./ShowError";
import _ from "loadsh";

function LoadingDialog({ loading, error, children }) {
    const store = useStore();

    useEffect(() => {
        if (loading) {
            openLoadingDialog(store, { type: "loading" });
        } else if (error) {
            openLoadingDialog(store, { type: "loading", entity: { error } });
        } else {
            closeLoadingDialog(store);
        }
    }, [loading, error]);

    return children;
}

export function LoadingScopeForActiveView({ children, dialog = false }) {
    const { loading, error } = useDeviceDataActiveViewLoadingState();
    const historyState = useHistoryState();

    return (
        <LoadingDialog loading={loading || historyState.loading} error={error || historyState.error} dialog={dialog}>
            {children}
        </LoadingDialog>
    );
}

export default function LoadingScope({ loading, error, children, dialog = false, dialogWithHiddenContent = false }) {
    const errorMessage = useMemo(() => {
        if (error) {
            if (error.data && !_.isEmpty(error.data.message)) {
                return error.data.message;
            } else {
                return ["entity.response.status", error];
            }
        } else {
            return null;
        }
    }, [error]);

    if (dialog) {
        return <LoadingDialog loading={loading} error={error} children={dialogWithHiddenContent ? loading || error ? <div /> : children : children} />;
    } else {
        if (loading) {
            return (
                <div>
                    <LinearProgress />
                </div>
            );
        } else if (errorMessage) {
            return (
                <div>
                    <ShowError header={"entity.loading.error"} description={errorMessage} />
                </div>
            );
        } else {
            return children;
        }
    }
}

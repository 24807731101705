export const beginFormConfig = [
    {
        id: "name",
        type: "TextField",
        props: {
            required: true,
            min: 2,
        },
    },
    {
        id: "enabled",
        type: "CheckboxField",
        props: {},
        defaultValue: true,
    },
];

export const customIdField = {
    id: "custom_id",
    type: "TextField",
    props: {
        required: true,
        yup(yupField, intl) {
            return yupField.customId(intl);
        },
    },
};

export const requestTimeConfig = [
    {
        type: "Group",
        fields: [
            {
                id: "osd_timer_seconds",
                type: "TimeoutField",
                props: {
                    required: true,
                },
                defaultValue: 60,
            },
            {
                id: "osd_timeout_seconds",
                type: "TimeoutField",
                props: {
                    required: true,
                },
                defaultValue: 60,
            },
        ],
    },
];

export const advancedFormConfig = [
    {
        type: "Group",
        fields: [
            {
                id: "lat",
                type: "NumberField",
                props: {},
            },
            {
                id: "lon",
                type: "NumberField",
                props: {},
            },
            {
                id: "alt",
                type: "NumberField",
                props: {},
            },
        ],
    },
    {
        type: "Group",
        fields: [
            {
                id: "road",
                type: "TextField",
                defaultValue: "",
                props: {},
            },
            {
                id: "road_km",
                type: "TextField",
                defaultValue: "",
                props: {},
            },
        ],
    },
    {
        id: "road_direction",
        type: "RadioGroupField",
        defaultValue: "1",
        props: {
            items: ["1", "2", "3", "4"], //numbers because data type of this value is VARCHAR(2)
            localizationContext: "forms.deviceDetail.directionValues",
        },
    },
    {
        id: "surface",
        type: "RadioGroupField",
        defaultValue: "unspecified",
        props: {
            items: ["unspecified", "asphalt", "concrete"],
            localizationContext: "forms.deviceDetail.surfaceValues",
        },
    },
    {
        id: "priority",
        type: "NumberField",
        defaultValue: "",
        props: {
            minValue: 0,
            maxValue: 65535,
        },
    },
    {
        id: "disabled_alerts",
        type: "AlertSelectionField",
    },
    {
        id: "camera_devices",
        type: "FormList",
        defaultValue: [],
        props: {
            formId: "cameraDevice",
            formConfig: [
                {
                    id: "http_url",
                    type: "UrlField",
                    props: {
                        required: true,
                    },
                },
                {
                    id: "osd_timer_seconds",
                    type: "TimeoutField",
                    props: {
                        required: true,
                        formControl: {
                            fullWidth: false,
                        },
                    },

                    defaultValue: 60,
                },
                {
                    id: "osd_timeout_seconds",
                    type: "TimeoutField",
                    props: {
                        required: true,
                        formControl: {
                            fullWidth: false,
                        },
                    },
                    defaultValue: 60,
                },
            ],
        },
    },
];

export const DriverInterfaces = {
    MeteoDevice: "meteo-device",
    MasterMeteoDevice: "master/meteo-device",
};

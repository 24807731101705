import React, { useMemo, useState } from "react";
import { FormControl, FormHelperText, Tooltip } from "@material-ui/core";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import { useAppAccess } from "../../Utils/Data/hooks/server";
import _ from "loadsh";
import { SingleEntitySelectionDialog } from "../SingleEntitySelectionDialog";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDomainGroupDevices } from "../../Api";
import { makeStyles } from "@material-ui/styles";
import { faRotateLeft } from "@fortawesome/pro-light-svg-icons";
import { ListField } from "./GlobalDeviceQueryField";

const Dialogs = {
    Domains: 0,
    DeviceGroups: 1,
};

const Selection = ["domain", "deviceGroup"];

function Delim() {
    return (
        <div style={{ color: "lightgray" }}>
            <FontAwesomeIcon icon={faArrowRight} />
        </div>
    );
}

const useDeviceFilterStyles = makeStyles((theme) => ({
    filterButton: {
        "&>.MuiButton-label": {
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: "left",
        },
    },
    allFilterButton: {
        "&>.MuiButton-label": {
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: "left",
        },
    },
}));

function DeviceFilterRow({ value, disabled, onChange, allowUnselectLast }) {
    const appAccess = useAppAccess();
    const intl = useIntl();
    const [dialog, setDialog] = useState(-1);
    const classes = useDeviceFilterStyles();

    const domains = useMemo(
        () =>
            _.map(appAccess.domains, (domain) => ({
                id: domain.id,
                name: domain.name,
            })),
        [appAccess]
    );

    const deviceGroups = useMemo(
        () => (_.size(value) > Dialogs.Domains ? _.filter(_.find(appAccess.domains, (domain) => domain.id === value[Dialogs.Domains].id)?.device_groups, (group) => group.id > 0) || [] : []),
        [domains, value]
    );

    const { devices } = useDomainGroupDevices({
        domainId: value[Dialogs.Domains]?.id,
        groupId: value[Dialogs.DeviceGroups]?.id,
    });

    const closeDialog = () => {
        setDialog(-1);
    };
    const onSelect = (selection) => {
        const newVal = _.clone(value || []);

        const newSelection = _.clone(selection);
        delete newSelection.permissions;

        newVal[dialog] = newSelection;

        if (_.isEmpty(newSelection)) {
            newVal.splice(dialog);
        } else if (dialog < _.size(value) - 1) {
            newVal.splice(dialog + 1);
        }

        onChange(newVal);
        closeDialog();
    };

    const valueSize = _.size(value);

    return (
        <>
            <div style={{ display: "flex", direction: "row", alignItems: "baseline" }}>
                {valueSize > Dialogs.Domains && (
                    <Tooltip title={value[Dialogs.Domains].name}>
                        <Button disabled={disabled} className={classes.filterButton} onClick={() => setDialog(Dialogs.Domains)}>
                            {value[Dialogs.Domains].name}
                        </Button>
                    </Tooltip>
                )}
                {valueSize > Dialogs.DeviceGroups && (
                    <>
                        <Delim />
                        <Tooltip title={value[Dialogs.DeviceGroups].name}>
                            <Button disabled={disabled} className={classes.filterButton} onClick={() => setDialog(Dialogs.DeviceGroups)}>
                                {value[Dialogs.DeviceGroups].name}
                            </Button>
                        </Tooltip>
                    </>
                )}

                {valueSize < Dialogs.DeviceGroups + 1 && (
                    <>
                        {valueSize > 0 && <Delim />}
                        <Tooltip title={formatForId(intl, `GlobalDeviceQueryFilter.${Selection[valueSize]}`)}>
                            <Button disabled={disabled} className={classes.allFilterButton} onClick={() => setDialog(valueSize)}>
                                {formatForId(intl, `GlobalDeviceQueryFilter.${Selection[valueSize]}`)}
                            </Button>
                        </Tooltip>
                    </>
                )}
                {allowUnselectLast && valueSize > 0 && (
                    <Tooltip title={formatForId(intl, `GlobalDeviceQueryFilter.clear`)}>
                        <Button
                            disabled={disabled}
                            className={classes.allFilterButton}
                            onClick={() => {
                                onChange(_.without(value, value[valueSize - 1]));
                            }}
                        >
                            <FontAwesomeIcon icon={faRotateLeft} />
                        </Button>
                    </Tooltip>
                )}
            </div>

            {dialog === Dialogs.Domains && (
                <SingleEntitySelectionDialog selection={value[Dialogs.Domains]} onSelect={onSelect} open={true} onClose={closeDialog} choices={domains} localizationContext={"DomainSelectionDialog"} />
            )}

            {dialog === Dialogs.DeviceGroups && (
                <SingleEntitySelectionDialog
                    selection={value[Dialogs.DeviceGroups]}
                    onSelect={onSelect}
                    open={true}
                    onClose={closeDialog}
                    choices={deviceGroups}
                    localizationContext={"DeviceGroupSelectionDialog"}
                />
            )}
        </>
    );
}

export function DomainGroupQueryField({ inputRef, fieldDef, required, fieldId, label, value, changeHandle, touched, errors, type, disabled, autoComplete }) {
    const currentValue = value || {
        filter: [],
    };

    const onFilterChange = (filter) => {
        changeHandle({ target: { name: fieldId, value: { ...currentValue, filter } } });
    };

    return (
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <FormControl
                fullWidth
                required={!!required}
                {...fieldDef.props?.formControl}
                style={{
                    border: "1px solid rgba(255, 255, 255, 0.23)",
                    borderRadius: 8,
                    marginTop: "1em",
                    marginBottom: "1em",
                }}
            >
                <ListField
                    disabled={disabled}
                    localizationContext={"GlobalDeviceQueryFilter"}
                    value={currentValue.filter}
                    onChange={onFilterChange}
                    component={({ value, idx, onChange }) => <DeviceFilterRow disabled={disabled} value={value} onChange={onChange} />}
                />

                {touched && Boolean(errors) && <FormHelperText error={true}>{errors}</FormHelperText>}
            </FormControl>
        </div>
    );
}

import React from "react";
import { useParams } from "react-router-dom";
import MasterDetailView from "../../../Components/MasterDetail/MasterDetailView";
import MasterDetailContextMenu from "../../../Components/MasterDetail/MasterDetailContextMenu";
import { DetailContextMenu } from "../../../Components/MasterDetail";
import ActionToolbar from "../../../Components/MasterDetail/ActionToolbar";
import MasterDetailTable from "../../../Components/MasterDetail/MasterDetailTable";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";
import _ from "loadsh";
import { useSafeOpenDetail } from "../../../Utils/Data/hooks/masterDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/pro-solid-svg-icons";
import DeviceDetail from "./DeviceDetail";
import { DriverInterfaces } from "./DeviceDetail.form";
import { useGetInMemoryDataList } from "../../../Utils/Data/hooks/dataList";
import ConfigurationTemplateDetail from "./ConfigurationTemplateDetail";
import { CreateDeleteButtonForList } from "../DeletionDialog";
import { useStore } from "react-redux";

export default function ConfigurationTemplates() {
    const { id } = useParams();
    const intl = useIntl();
    const store = useStore();
    const domainId = parseInt(id, 10);
    const safeOpenDetail = useSafeOpenDetail();

    const columns = [
        {
            field: "name",
            width: 150,
            sortable: true,
            renderCell: (params) => (
                <Button>
                    <FontAwesomeIcon icon={faDatabase} />
                    &nbsp; {params.row.name}
                </Button>
            ),
        },
        {
            field: "device_count",
            width: 150,
            align: "center",
            sortable: true,
        },
        CreateDeleteButtonForList(intl, store, "domain/" + domainId + "/configuration-template/", undefined, (row) => row.device_count !== 0),
    ];

    const dataSource = useGetInMemoryDataList({
        url: "domain/" + domainId + "/configuration-template/list/",
        defaultSort: { field: "name", isAscending: true },
    });

    return (
        <MasterDetailView
            loading={dataSource.loading}
            loadingError={dataSource.error}
            onRenderContextMenu={({ isDetailOpened, viewMode }) => (
                <MasterDetailContextMenu
                    isDetailOpened={isDetailOpened}
                    viewMode={viewMode}
                    onRenderDetailMenu={() => <DetailContextMenu onRenderBeforeLabel={() => <FontAwesomeIcon icon={faDatabase} />} />}
                    onRenderMasterMenu={() => (
                        <ActionToolbar
                            localizationContext={"pages.configurationTemplate"}
                            actions={[
                                {
                                    id: "create",
                                    onClick() {
                                        safeOpenDetail("configurationTemplate", {
                                            isNew: true,
                                            domain_id: domainId,
                                            group_id: -1 * domainId,
                                        });
                                    },
                                },
                            ]}
                            searchFieldName={"name"}
                        />
                    )}
                />
            )}
            onRenderDetail={() => <ConfigurationTemplateDetail />}
        >
            <MasterDetailTable dataSource={dataSource} entityType={"configurationTemplate"} columns={columns} />
        </MasterDetailView>
    );
}

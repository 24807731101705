import { useFormik } from "formik";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { CustomTextAreaField, CustomTextField } from "./CustomTextField";
import { CheckBoxField } from "./CheckBoxField";
import { DropdownField } from "./DropdownField";
import { CheckForm } from "./CheckForm";

import React from "react";
import _ from "underscore";
import FormList from "./FormList";
import { toFixed } from "../../Utils/Data/FloatFormatter";
import { GlobalDeviceQueryField } from "./GlobalDeviceQueryField";
import { DateRangeFormField } from "./DateRangeField";
import { AlertFilter } from "./AlertFilter";
import RadioGroupField from "./RadioGroupField";
import { GeoLocationDialogForm } from "./GeoLocationDialogForm";
import { DomainGroupQueryField } from "./DomainGroupQueryField";
import { AlertSelectionField } from "./AlertSelectionField";

const Fields = {
    TextField: {
        yup: () => yup.string(),
    },
    PortField: {
        yup: () => yup.number().positive().max(0xffff),
    },
    TimeoutField: {
        yup: () => yup.number().positive(),
    },
    IpFieldV4: {
        yup: (intl) => yup.string().ipv4(intl),
    },
    UrlField: {
        yup: () => yup.string().url(),
        component: (props) => <CustomTextField key={props.fieldId} type={"text"} {...props} />,
    },
    UrlWithDebugField: {
        yup: (intl) => yup.string().urlWithDebug(intl),
        component: (props) => <CustomTextField key={props.fieldId} type={"text"} {...props} />,
    },
    PwdField: {
        yup: () => yup.string(),
        component: (props) => <CustomTextField autoComplete={"new-password"} key={props.fieldId} type={"password"} {...props} />,
    },
    TextAreaField: {
        yup: () => yup.string(),
        component: (props) => <CustomTextAreaField key={props.fieldId} {...props} />,
    },
    TelephoneNumberField: {
        yup: (intl) => yup.string().phone(intl),
    },
    CheckboxField: {
        yup: () => yup.boolean(),
        component: (props) => <CheckBoxField key={props.fieldId} {...props} />,
    },
    CheckForm: {
        yup: (intl, { required }) => {
            let validator = yup.array().of(yup.string().min(1));
            if (required) {
                validator = validator.min(1);
            }
            return validator;
        },
        component: (props) => <CheckForm key={props.fieldId} {...props} items={props.fieldDef.items} languageContext="permissions" multiple={props.fieldDef.props.multiple} />,
    },

    DropdownField: {
        yup: (intl, { required, multiple }) => {
            if (required) {
                let validator;
                if (multiple) {
                    validator = yup.array().of(yup.string().min(1));
                    if (required) {
                        validator = validator.min(1);
                    }
                } else {
                    validator = yup.string();
                    if (required) {
                        validator = validator.required();
                    }
                }

                return validator;
            } else {
                return null;
            }
        },
        component: (props) => <DropdownField key={props.fieldId} {...props} languageContext={props.fieldDef.props.languageContext} multiple={props.fieldDef.props.multiple} />,
    },
    RadioGroupField: {
        yup: (intl, { required }) => {
            let validator;
            validator = yup.string();
            if (required) {
                validator = validator.required();
            }
            return validator;
        },
        component: (props) => <RadioGroupField key={props.fieldId} {...props} languageContext={props.fieldDef.props.languageContext} />,
    },

    NumberField: {
        yup: (intl, { required, minValue, maxValue }) => {
            let validator = yup.mixed().transform((v) => {
                if (_.isString(v) && !_.isEmpty(v)) {
                    return parseFloat(v.replace(/,/g, "."));
                } else {
                    return v;
                }
            });

            if (required) {
                validator = validator.required();
            }

            validator = validator.test("is-number", formatForId(intl, "formField.invalidNumber"), (value, context) => {
                return value === null || typeof value === "undefined" || _.isFinite(value);
            });

            if (minValue !== undefined) {
                validator = validator.test("minValue", formatForId(intl, "formField.minValue", { value: minValue }), (val) => val === null || typeof val === "undefined" || val >= minValue);
            }

            if (maxValue !== undefined) {
                validator = validator.test("maxValue", formatForId(intl, "formField.maxValue", { value: maxValue }), (val) => val === null || typeof val === "undefined" || val <= maxValue);
            }

            return validator;
        },
        component: (props) => (
            <CustomTextField
                key={props.fieldId}
                type={"text"}
                formatter={(value) => {
                    if (typeof value === "number" && _.isFinite(value)) {
                        return toFixed(value, 6);
                    }
                    return value;
                }}
                {...props}
            />
        ),
    },
    Default: {
        yup: () => yup.string(),
        component: (props) => <CustomTextField key={props.fieldId} type={"text"} {...props} />,
    },
    FormList: {
        yup: (intl, props) => {
            if (props.formConfig) {
                return yup.array().of(buildFormValidation(intl, props.formConfig));
            } else {
                return yup.array();
            }
        },
        component: (props) => <FormList key={props.fieldId} {...props} />,
    },
    QueryField: {
        yup: () => yup.object(),
        component: (props) => <GlobalDeviceQueryField key={props.fieldId} {...props} />,
    },
    AlertFilter: {
        yup: () => yup.object(),
        component: (props) => <AlertFilter key={props.fieldId} {...props} />,
    },
    DateRangeField: {
        yup: () => yup.object(),
        component: (props) => <DateRangeFormField {...props} />,
    },
    GeoLocationField: {
        yup: () => yup.object(),
        component: (props) => <GeoLocationDialogForm key={props.fieldId} {...props} />,
    },
    DomainGroupQueryField: {
        yup: () => yup.object(),
        component: (props) => <DomainGroupQueryField key={props.fieldId} {...props} />,
    },
    AlertSelectionField: {
        yup: () => yup.array().nullable(true),
        component: (props) => <AlertSelectionField key={props.fieldId} {...props} />,
    },
};

export function useFormCommon(formConfig, initialValues, onSubmit) {
    const intl = useIntl();
    const validationSchema = React.useMemo(() => buildFormValidation(intl, formConfig), [formConfig]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: false,
        validateOnChange: false,
        onSubmit: (values) =>
            onSubmit(
                { ...initialValues, ...validationSchema.cast(values) },
                {
                    setErrors(errors) {
                        // console.log(errors);
                        let formikErrors = {};
                        for (const error of errors) {
                            let { id, message, tokens } = error;
                            if (message.startsWith("T:")) {
                                message = formatForId(intl, message.substring(2), tokens);
                            }
                            //console.log(message);

                            formikErrors[id] = message;
                        }

                        formik.setErrors(formikErrors);
                    },
                }
            ),
    });

    React.useEffect(() => {
        console.error(formik.errors);
    }, [formik.errors]);

    return formik;
}

export function RenderField(props) {
    const { intl, errors, touched, changeHandle, item, formik, formId, focusFieldId, ref, editable, isNew, fieldId, value, orderIndex, classes } = props;

    if (item.type === "Group") {
        return (
            <div className={classes && classes.formGroup}>
                {_.map(item.fields, (item) =>
                    RenderField({
                        ...props,
                        item: item,
                    })
                )}
            </div>
        );
    } else {
        const fieldComponent = Fields[item.type]?.component || Fields.Default.component;
        const usedId = fieldId || item.id;

        return fieldComponent({
            intl,
            changeHandle,
            formik: formik,
            localizationContext: item.localizationContext || formId,
            value: value || formik.values[item.id],
            fieldId: usedId,
            label: formatForId(intl, `forms.${formId}.${item.id}`, { orderIndex }),
            touched: touched || formik.touched[usedId],
            errors: errors || formik.errors[usedId],
            disabled: !editable || formik.isSubmitting || formik.isValidating || (item.props?.immutable && !isNew),
            required: item.props?.required,
            fieldDef: item,
            inputRef: item.id === focusFieldId && ref ? (input) => (ref.current = input) : null,
        });
    }
}

export function buildFormEntityValues(formConfig, formData) {
    let ret = { ...formData };

    const handleField = (item) => {
        if (item.type === "Group") {
            item.fields.forEach(handleField);
        } else if (typeof item.id !== "undefined") {
            const value = formData?.[item.id];
            if (typeof value !== "undefined") {
                ret[item.id] = value;
            } else if (typeof item.defaultValue !== "undefined") {
                ret[item.id] = item.defaultValue;
            } else {
                ret[item.id] = "";
            }
        }
    };

    formConfig.forEach(handleField);

    return ret;
}

function parseItemProps(props) {
    let parsedProps = {};

    _.forEach(props, (value, key) => {
        if (_.has(value, "type") && _.has(value, "value")) {
            parsedProps[key] = value.value;
        } else {
            parsedProps[key] = value;
        }
    });

    return parsedProps;
}

export function buildFormValidation(intl, formConfig) {
    let schema = {};

    const buildValidation = (item) => {
        if (item.type === "Group") {
            item.fields.forEach(buildValidation);
            return;
        }

        let fieldSpec = Fields[item.type] || Fields.Default;

        let props = {};
        if (item.hasOwnProperty("props")) {
            props = parseItemProps(item.props);
        }

        let yupField = fieldSpec.yup(intl, props);
        if (!yupField) return;

        if (item.hasOwnProperty("props")) {
            const { min, max, required, yup } = props;

            if (required) {
                yupField = yupField.required();
            }

            if (typeof min === "number") {
                yupField = yupField.min(min);
            }

            if (typeof max === "number") {
                yupField = yupField.max(max);
            }

            if (_.isFunction(yup)) {
                yupField = yup(yupField, intl, item, formConfig);
            }

            if (yupField && !required) {
                yupField = yupField.nullable(true);
            }
        }

        schema[item.id] = yupField;
    };

    formConfig.forEach(buildValidation);

    return yup.object(schema);
}

export function findFirstFocusableFieldId(formConfig, isNew) {
    if (_.isEmpty(formConfig)) return null;

    const isImmutable = (field) => field.props?.immutable && !isNew;
    const focusable = _.find(formConfig, (field) => !field?.props?.focusable);
    if (focusable && !isImmutable(focusable)) {
        return focusable.id;
    }

    return _.find(formConfig, (field) => !isImmutable(field) && field.type !== "DropdownField")?.id;
}

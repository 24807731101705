import reduxWebsocket from "@giantmachines/redux-websocket";
import _ from "underscore";
import moment from "moment";

import defaultState from "./server.default";

function handleDeviceSubscription(data, state) {
    const newDevData = {};
    for (const id of data) {
        const key = "id:" + id;
        const device = state.devData[key];
        if (device) {
            newDevData[key] = device;
        }
    }

    return {
        ...state,
        devData: newDevData,
    };
}

export default function serverReducer(state = defaultState, action) {
    switch (action.type) {
        case "REDUX_WEBSOCKET::OPEN":
            return {
                ...state,
                connected: true,
            };
        case "REDUX_WEBSOCKET::BROKEN":
        case "REDUX_WEBSOCKET::CLOSED":
            console.log("websocket connection broken or closed ", action);
            return {
                ...state,
                connected: false,
            };
        case "REDUX_WEBSOCKET::MESSAGE":
            const parsed = JSON.parse(action.payload.message);
            const { is_global } = parsed;
            switch (parsed?.[0]) {
                case "BuildInfo": {
                    return { ...state, buildInfo: parsed[1] };
                }
                case "global-state": {
                    return {
                        ...state,
                        globalState: { ...state.globalState, ...parsed[1], last_change_time: moment().valueOf() },
                    };
                }
                case "event": {
                    const { event_name, camera_id, domain_id, device_group_id, device_id, view_ids } = parsed[1];
                    switch (event_name) {
                        case "cam-img": {
                            const change = {};
                            change["cam:" + camera_id] = Date.now();

                            return { ...state, camImages: { ...state.camImages, ...change } };
                        }
                        case "domain_deactivated": {
                            const changeTime = Date.now();
                            const result = {
                                ...state,
                                uiRefresh: {
                                    ...state.uiRefresh,
                                    domains: { ...state.uiRefresh.domains, [domain_id]: changeTime },
                                    global: changeTime,
                                },
                            };

                            return result;
                        }
                        case "domain_activated": {
                            const changeTime = Date.now();
                            const result = {
                                ...state,
                                uiRefresh: {
                                    ...state.uiRefresh,
                                    domains: { ...state.uiRefresh.domains, [domain_id]: changeTime },
                                    global: changeTime,
                                },
                            };

                            return result;
                        }
                        case "domain_changed": {
                            const changeTime = Date.now();
                            const result = {
                                ...state,
                                uiRefresh: {
                                    ...state.uiRefresh,
                                    domains: { ...state.uiRefresh.domains, [domain_id]: changeTime },
                                    global: changeTime,
                                },
                            };

                            return result;
                        }
                        case "dev_changed": {
                            const changeTime = Date.now();

                            const result = {
                                ...state,
                                uiRefresh: {
                                    ...state.uiRefresh,
                                    devGroups: { ...state.uiRefresh.deviceGroups, [device_group_id]: changeTime },
                                    devices: { ...state.uiRefresh.devices, [device_id]: changeTime },
                                },
                            };

                            if (is_global) {
                                result.uiRefresh.global = changeTime;
                            }

                            return result;
                        }
                        case "dev_group_changed": {
                            const changeTime = Date.now();

                            const result = {
                                ...state,
                                uiRefresh: {
                                    ...state.uiRefresh,
                                    devGroups: { ...state.uiRefresh.deviceGroups, [device_group_id]: changeTime },
                                    domains: { ...state.uiRefresh.domains, [domain_id]: changeTime },
                                    global: changeTime,
                                },
                            };

                            return result;
                        }
                        case "view_changed": {
                            const changeTime = Date.now();
                            const result = {
                                ...state,
                                uiRefresh: {
                                    ...state.uiRefresh,
                                    viewChangeCounter: changeTime,
                                    views: { ...state.uiRefresh.views },
                                },
                            };

                            for (const view_id of view_ids) {
                                result.uiRefresh.views[view_id] = changeTime;
                            }

                            if (is_global) {
                                result.uiRefresh.global = changeTime;
                            }
                            return result;
                        }
                        case "radar-img": {
                            const changeTime = Date.now();

                            return {
                                ...state,
                                uiRefresh: {
                                    ...state.uiRefresh,
                                    radarImageChangeCounter: changeTime,
                                },
                            };
                        }
                        default:
                            console.warn("unhandled event", event_name, parsed[1]);
                    }

                    return state;
                }
                case "dev-data": {
                    const historyActive = state.historyDate != null;

                    let devData = historyActive ? state.liveData : state.devData;

                    parsed[1].forEach((item) => {
                        if (!_.isEmpty(item)) {
                            item.lastCommFormatted = moment(item.last_communication).format("LLL");
                            devData["id:" + item.id] = item;
                        }
                    });

                    if (historyActive) {
                        return {
                            ...state,
                            liveData: devData,
                        };
                    } else {
                        return {
                            ...state,
                            devData: devData,
                            liveData: devData,
                        };
                    }
                }
                case "AppAccess": {
                    return { ...state, appAccess: parsed[1] };
                }

                default:
                    console.warn("unhandled ws data: " + parsed?.[0] + ", data: ", parsed?.[1]);
                    return state;
            }
        case "REDUX_WEBSOCKET::SEND":
            const [cmd, data] = action.payload;
            if (cmd === "subs-dev") {
                return handleDeviceSubscription(data, state);
            }
            return state;
        case "REDUX_WEBSOCKET::CONNECT":
            return state;
        case "METEO::HISTORY_CHANGE":
            const historyActive = action.payload.message != null;

            if (historyActive) {
                const devDataClone = JSON.parse(JSON.stringify(state.devData));

                return {
                    ...state,
                    historyDate: action.payload.message,
                    liveData: devDataClone,
                    devData: {},
                };
            } else {
                const liveDataClone = JSON.parse(JSON.stringify(state.liveData));
                return {
                    ...state,
                    historyDate: action.payload.message,
                    devData: _.isEmpty(liveDataClone) ? state.devData : liveDataClone,
                };
            }
        case "METEO::HISTORY_DATA":
            let devData = state.devData;

            Object.values(action.payload.message).forEach((item) => {
                item.lastCommFormatted = moment(item.last_communication).format("LLL");
                devData["id:" + item.id] = item;
            });

            return {
                ...state,
                devData: devData,
            };
        case "METEO::HISTORY_DATA_STATE":
            return {
                ...state,
                historyState: action.payload,
            };

        case "REDUX_WEBSOCKET::ERROR": {
            console.log("websocket error ", action);
            return state;
        }

        default:
            return state;
    }
}

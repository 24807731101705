import React, { useEffect, useMemo, useState } from "react";
import { useGet } from "restful-react";
import { useSelector, useStore } from "react-redux";
import { sendToWs } from "../actions/server";
import _ from "loadsh";
import { formatTime } from "../Time";
import { useTheme } from "@material-ui/core";
import { setHistoryEndDate } from "../actions/gui";
import moment from "moment";

export function useDeviceSubscription(devices) {
    const store = useStore();
    const online = useSelector(({ server }) => {
        return server?.connected;
    });
    const appAccess = useAppAccess();

    useEffect(() => {
        if (online && !_.isEmpty(devices)) {
            //FIXME hack - remove timemout
            const timer = setTimeout(() => sendToWs(store, ["subs-dev", devices.map((device) => device.id)]), 100);
            return () => clearTimeout(timer);
        }
    }, [devices, online, appAccess]);
}

export function useDomainDeviceGroups(domainId) {
    const { data, refetch, loading, error } = useGet({
        lazy: true,
        path: "domain/" + domainId + "/device-group/list/",
    });
    const deviceGroups = useMemo(() => {
        if (_.isEmpty(data)) return [];

        const { devices } = _.find(data, (group) => group.id < 0);
        const refDevices = new Map();
        for (const dev of devices) {
            refDevices.set(dev.id, { ...dev, name: dev.name.substring(0, dev.name.length - 3) });
        }

        return _.map(data, (group) => ({
            ...group,
            devices: _.map(group.devices, (device) => {
                if (device.running_device_id) {
                    return { ...device, referencedDataSource: refDevices.get(device.running_device_id) };
                } else {
                    return { ...device, referencedDataSource: null };
                }
            }),
        }));
    }, [data]);

    return { deviceGroups, refetch, loading, error };
}

export function useDeviceHistoryData(devices) {
    const store = useStore();
    const historyDate = useSelector(({ server }) => {
        return server?.historyDate;
    });

    const historyUrl = useMemo(() => {
        if (!_.isEmpty(devices) && historyDate) {
            return "history/device/?device_ids=" + devices.map((item, idx) => item.id).join(",") + "&time=" + historyDate.valueOf();
        } else return "";
    }, [devices, historyDate]);

    const {
        data: historyDataApi,
        refetch: refetchHistoryData,
        loading: loadingHistoryData,
        error: errorHistoryData,
    } = useGet({
        path: historyUrl,
        lazy: true,
    });

    useEffect(() => {
        store.dispatch({
            type: "METEO::HISTORY_DATA_STATE",
            payload: _.isEmpty(historyUrl) ? {} : { error: errorHistoryData, loading: loadingHistoryData },
        });
    }, [loadingHistoryData, errorHistoryData, historyDate]);

    useEffect(() => {
        if (historyDataApi) {
            store.dispatch({ type: "METEO::HISTORY_DATA", payload: { message: historyDataApi.devices } });
        }
    }, [historyDataApi]);

    useEffect(async () => {
        if (!_.isEmpty(historyUrl)) {
            await refetchHistoryData({ path: historyUrl });
        }
    }, [historyUrl]);
}

export function useDevicesData(domainId, groupId) {
    const { deviceGroups, refetch, loading, error } = useDomainDeviceGroups(domainId);

    const devices = useMemo(() => {
        if (deviceGroups) {
            let devices = _.find(deviceGroups, (group) => group.id === groupId)?.devices;
            if (devices) {
                devices = _.clone(devices);
                devices.sort((a, b) => a.name.localeCompare(b.name));
                return devices;
            }
        }
        return [];
    }, [deviceGroups]);

    useDeviceSubscription(devices);
    useDeviceHistoryData(devices);

    const appAccess = useAppAccess();
    const changeToken = useDeviceGroupChangeHandler(groupId);

    const [lastChangeToken, setLastChangeToken] = useState(changeToken);
    React.useEffect(() => {
        if (lastChangeToken !== changeToken) {
            console.log(formatTime(Date.now(), true), "refreshing due change token", changeToken);

            refetch();
            setLastChangeToken(changeToken);
        } else {
            console.log(formatTime(Date.now(), true), "refresh ignored");
        }
    }, [appAccess, changeToken]);

    return [devices, loading, error, refetch];
}

export function useViewDevicesData(viewId) {
    const { data, refetch, loading, error } = useGet({
        lazy: true,
        path: `view/access/devices/?id=${viewId}`,
    });

    const devices = useMemo(() => (data ? data.devices : []), [data]);

    useDeviceSubscription(devices);
    useDeviceHistoryData(devices);

    const appAccess = useAppAccess();
    const viewChangeHandler = useViewChangeHandler({ viewId });

    React.useEffect(() => {
        refetch();
    }, [appAccess, viewChangeHandler, viewId]);

    return [devices, loading, error, refetch, data?.view];
}

export function useDomainChangeHandler(domainId) {
    return useSelector(({ server }) => server.uiRefresh.domains[domainId]);
}

export function useDeviceGroupChangeHandler(deviceGroupId) {
    return useSelector(({ server }) => server.uiRefresh.devGroups[deviceGroupId]);
}

export function useDeviceChangeHandler(deviceIds) {
    return useSelector(({ server }) => deviceIds.map((id) => server.uiRefresh.devices[id]).join(";"));
}

export function useGlobalChangeHandler() {
    return useSelector(({ server }) => {
        return server.uiRefresh.global;
    });
}

export function useViewsChangeHandler() {
    return useSelector(({ server }) => {
        return server.uiRefresh.viewChangeCounter;
    });
}

export function useViewChangeHandler({ viewId }) {
    return useSelector(({ server }) => {
        return server.uiRefresh.views[viewId];
    });
}

export function useDeviceState(deviceId) {
    return useSelector(({ server }) => {
        let ret = server?.devData["id:" + deviceId];
        return ret ? ret : {};
    });
}

export function useCameraImageState(cameraId) {
    return useSelector(({ server }) => {
        if (server.historyDate) {
            return server.historyDate.valueOf();
        } else {
            let ret = server?.camImages["cam:" + cameraId];
            return ret ? ret : 0;
        }
    });
}

export function useDevicesState(devicesId) {
    return useSelector(({ server }) => {
        let ret = {};
        devicesId.forEach((id) => {
            ret[id] = server?.devData["id:" + id];
        });
        return ret;
    });
}

function hasNotConfirmedWarning(warnings) {
    if (!warnings) return false;

    for (const warning of warnings) {
        if (!warning.confirmation) {
            return true;
        }
    }

    return false;
}

export function useActiveAlertCounter() {
    return useSelector(({ server }) => {
        return {
            notConfirmedActiveAlerts: server.globalState.not_confirmed_alerts,
            maxLevel: server.globalState.not_confirmed_alerts_max_level,
            notConfirmedConfirmableAlerts: server.globalState.not_confirmed_confirmable_alerts,
            confirmableMaxLevel: server.globalState.not_confirmed_confirmable_alerts_max_level,
        };
    });
}

export function useActiveGeoAlertCounter() {
    return useSelector(({ server }) => {
        return {
            notConfirmedActiveGeoAlerts: server.globalState.not_confirmed_geo_alerts,
            maxLevel: server.globalState.not_confirmed_geo_alerts_max_level,
        };
    });
}

export function useGlobalStateRefresh() {
    return useSelector(({ server }) => {
        return server.globalState.last_change_time;
    });
}

export function useActiveGeoAlertRefresh() {
    return useSelector(({ server }) => {
        return server.globalState.not_confirmed_geo_alerts_change_time;
    });
}

export function useActiveTextForecast() {
    return useSelector(({ server }) => {
        const textForecastId = server.globalState.current_text_forecast_id;
        const lastReadTextForecastId = server.globalState.last_read_text_forecast_id;

        const isRead = textForecastId === lastReadTextForecastId;

        return {
            textForecastId,
            lastReadTextForecastId,
            isRead,
        };
    });
}

export function useVisibleDeviceIds() {
    return useSelector(({ server }) => _.map(server.devData, (device) => device.id));
}

export function useAppAccess() {
    return useSelector(({ server }) => server.appAccess);
}

export function useBuildInfo() {
    const result = useSelector(({ server }) => server.buildInfo);

    const [state, setState] = useState(false);
    useEffect(() => {
        const [time, none, buildNumber, hash] = result.split(/[-]|git:|@|build number/);
        if (state && buildNumber + hash !== state) {
            forceReloadPage();
        }
        setState(buildNumber + hash);
    }, [result]);

    return result;
}

function forceReloadPage() {
    fetch(window.location.href, {
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            Pragma: "no-cache",
            Expires: -1,
            "Cache-Control": "no-cache",
        },
    }).then(function () {
        window.location.reload(true);
    });
}

export function useHistoryData(date) {
    const store = useStore();

    const startUsing = () => {
        store.dispatch({ type: "METEO::HISTORY_CHANGE", payload: { message: date } });
        setHistoryEndDate(store, moment().add(15, "minute"));
    };

    useEffect(() => {
        if (date) {
            startUsing();
        } else {
            stopUsingHistoryData(store);
        }

        return () => {
            stopUsingHistoryData(store);
        };
    }, [date]);
}

export function stopUsingHistoryData(store) {
    store.dispatch({ type: "METEO::HISTORY_CHANGE", payload: { message: null } });
    setHistoryEndDate(store, null);
}

export function useServerConnected() {
    return useSelector(({ server }) => server.connected);
}

export function useHistoryGetData() {
    return useSelector(({ server }) => {
        return server.historyDate;
    });
}

export function useIsHistoryActive() {
    const historyDate = useHistoryGetData();
    return !_.isEmpty(historyDate);
}

export function useValueColor(valueInfo, warnings, value, historyActive) {
    const theme = useTheme();

    return useMemo(() => {
        let worstSeverity = 0;
        if (value !== undefined && warnings && valueInfo && valueInfo?.hasOwnProperty("warningDeps")) {
            warnings.forEach((wrn) => {
                if (wrn.level === 1 || wrn.level === 2 || wrn.level === 3) {
                    const warningDepInfo = valueInfo.warningDeps.find((item) => item.key === wrn.type);
                    if (warningDepInfo && worstSeverity < wrn.level && (!warningDepInfo.hasOwnProperty("minLevel") || wrn.level >= warningDepInfo.minLevel)) {
                        worstSeverity = wrn.level;
                    }
                }
            });

            const color = theme.palette.warnings[`level_${worstSeverity}`];

            if (color) {
                return color;
            }
        }

        if (historyActive) {
            return { textColor: theme.palette.historyActiveColor };
        } else return {};
    }, [warnings, value]);
}

export function useHistoryState() {
    return useSelector(({ server }) => server.historyState);
}

export function useRadarImageChangeHandler() {
    return useSelector(({ server }) => {
        return server.uiRefresh.radarImageChangeCounter;
    });
}
